// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/alextarkowski/Desktop/apps/libation/my-blazing-fast-site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-studies-js": () => import("/Users/alextarkowski/Desktop/apps/libation/my-blazing-fast-site/src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-js": () => import("/Users/alextarkowski/Desktop/apps/libation/my-blazing-fast-site/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/alextarkowski/Desktop/apps/libation/my-blazing-fast-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/Users/alextarkowski/Desktop/apps/libation/my-blazing-fast-site/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-services-js": () => import("/Users/alextarkowski/Desktop/apps/libation/my-blazing-fast-site/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-ambassadorship-representation-js": () => import("/Users/alextarkowski/Desktop/apps/libation/my-blazing-fast-site/src/pages/services/ambassadorship-representation.js" /* webpackChunkName: "component---src-pages-services-ambassadorship-representation-js" */),
  "component---src-pages-services-development-training-js": () => import("/Users/alextarkowski/Desktop/apps/libation/my-blazing-fast-site/src/pages/services/development-training.js" /* webpackChunkName: "component---src-pages-services-development-training-js" */),
  "component---src-pages-services-vip-events-parties-js": () => import("/Users/alextarkowski/Desktop/apps/libation/my-blazing-fast-site/src/pages/services/vip-events-parties.js" /* webpackChunkName: "component---src-pages-services-vip-events-parties-js" */),
  "component---src-pages-services-workplace-culture-harassment-safety-js": () => import("/Users/alextarkowski/Desktop/apps/libation/my-blazing-fast-site/src/pages/services/workplace-culture-harassment-safety.js" /* webpackChunkName: "component---src-pages-services-workplace-culture-harassment-safety-js" */),
  "component---src-pages-team-js": () => import("/Users/alextarkowski/Desktop/apps/libation/my-blazing-fast-site/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/alextarkowski/Desktop/apps/libation/my-blazing-fast-site/.cache/data.json")

